import React, { Component } from 'react';
import cloudinary from 'cloudinary-jquery-file-upload/cloudinary-jquery-file-upload.js'
import jquery from 'jquery/dist/jquery.js';
import blueImpWidget from 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import blueImpTransport from 'blueimp-file-upload/js/jquery.iframe-transport.js'
import blueImpUpload from 'blueimp-file-upload/js/jquery.fileupload.js'




class uploads extends Component { 

     constructor(props){
       super(props)
       this.state={
       cloudImage: this.props.visualA,
       apiUrl:`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${this.props.resourceRef}/upload-image-to-cloud`
       }

       this.Imageupload = this.Imageupload.bind(this)
       this.newImageUpload = this.newImageUpload(this)
     }


   async componentDidUpdate(){
    //this.Imageupload()
   }

   async componentDidMount(){

       await this.Imageupload()
      this.props.taskStatus(false)
    
   

   }

    
  async Imageupload(e) {

    await  jquery.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
    // Initiate upload

var myWidget = window.cloudinary.createUploadWidget({
cloudName: 'www-code4teen-tech', 
uploadPreset: 'csizcotf'}, (error, result) => { 
  if (!error && result && result.event === "success") { 
    console.log('Done! Here is the image info: ', result.info.secure_url); 

      this.setState({
           cloudImage:result.info.secure_url
         })
      this.props.taskStatus(true)

  }
}
)


document.getElementById("upload_widget").addEventListener("click", function(){
  myWidget.open();
}, false);


}


async newImageUpload(){ 


  
    
}


   render(){

   	return(

      	<div className="container top-right-curve bottom-right-curve container-pad">

          <div className="row standard-padding-tb-3  top-right-curve "> 
          <div className="col-12 text-center standard-padding-tb-3">
          <h4 className="header-font black-text"> 
            {this.props.heading}
          </h4>
          </div>
          

           <div className="col-md-4">

           <img src={this.state.cloudImage} width='330' height="315" />

           </div>

            <div className="col-md-8">

            <p className="header-font blue-text">
                 {this.props.explanationA}
            </p>


         
          <div className="col-12 standard-padding-tb-3">
            <button id="upload_widget" className="btn btn-outline-success cloudinary-button" > Upload a picture and recieve a URl below </button>
            </div>
          

           
           </div>
     
          </div> 

  <div className="row bottom-right-curve black-background"> 


 <div className="col-12 padding-off bottom-right-curve text-center standard-padding-tb-3"> 
  <figure> 
           <h5 className="header-font blue-text"> Your URL is here </h5>
           <figcaption className="body-font blue-text"> 
               <h6>  {this.state.cloudImage} </h6>
           </figcaption>
           </figure>
  </div>


 </div>
          

          </div>
)




   }


}


export default uploads
import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import cloudinary from 'cloudinary-jquery-file-upload/cloudinary-jquery-file-upload.js'
import blueImpWidget from 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import blueImpTransport from 'blueimp-file-upload/js/jquery.iframe-transport.js'
import blueImpUpload from 'blueimp-file-upload/js/jquery.fileupload.js'
import $ from 'jquery/dist/jquery.js';
import Pusher from 'pusher-js';
import pushid from 'pushid';
import axios from 'axios';
import atclogo from '../../images/loader.png';
import '../../App.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

    class Mirror extends Component{
      constructor(props) {
        super(props);
        this.state = {
          id:'',
          html:'',
          resourceRef:this.props.resourceRef,
          title:'',
          codeSnippet:'testing',
          taskDescription:'',
          taskA:'',
          taskB:'',
          taskC:'',
          taskD:'',
          taskE:'',
          taskF:'',
          taskG:'',
          positiveAlert:'',
          negativeAlert:'',
          taskByteValue:'',
          taskRecall:'',
          taskTag:'',
          projectName:'',
          cloudImage:'',
          saveModal:false
        };

  this.fetchEditorResource = this.fetchEditorResource.bind(this)
  this.textReader = this.textReader.bind(this)
  this.runCode = this.runCode.bind(this)
  this.retrievePreviousEditorTaskResponse = this.retrievePreviousEditorTaskResponse.bind(this)
  this.openSaveToPortfolioModal = this.openSaveToPortfolioModal.bind(this)
  this.Imageupload = this.Imageupload.bind(this)
  this.storeCodeToPortfolio = this.storeCodeToPortfolio.bind(this)
      }

    async componentDidUpdate() {
     
      }

     async componentDidMount() {
    
         console.log(this.state.resourceRef);
        //const {match:{params}} = this.props   
        //console.log(params.topidRef);
           await this.fetchEditorResource()

          this.props.taskStatus(false)
          this.props.byteState(this.state.taskByteValue)

          this.setState({
            positiveAlert:false,
            negativeAlert:false
          })
          this.Imageupload()
      }

      async fetchEditorResource() { 
    const requestedResource = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${this.state.resourceRef}/editorTask`,{
      method:"POST",
      headers:{
        'Content-Type': "application-JSON"
      }, 
      mode:"cors", 
      credentials:"include"
    })
       .then(response => response.json())
       .then(response => { 
             console.log(response)

             this.setState({ 
                  taskDescription: response.taskDescription,
                  codeSnippet: response.codeSnippet,
                  taskA: response.taskA, 
                  taskB: response.taskB, 
                  taskC: response.taskC, 
                  taskD: response.taskD,
                  taskE: response.taskE,
                  taskF: response.taskF, 
                  taskG: response.taskG,
                  taskByteValue: response.byteValue,
                  taskRecall: response.recall,
                  taskTag : response.tag
             }, ()=> { 
                  if(response.recall){
                    this.retrievePreviousEditorTaskResponse()
                  }
               

                    
             })

             console.log(this.state)
       })
 }

async retrievePreviousEditorTaskResponse(){ 
      
  const requestedEditorResponse = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${this.state.taskTag}/editorResponse`,{
      method:"POST",
      headers:{
        'Content-Type': "application-JSON"
      }, 
      mode:"cors", 
      credentials:"include"
    })
       .then(response => response.json())
       .then(response => { 
           console.log(response.response)
          this.setState({ 
                codeSnippet : response.response
          })
                
       })      
}

async textReader(){

  console.log('spoke')

  $.when(window.responsiveVoice.speak(`Ok`))
  .then(window.responsiveVoice.speak(`${this.state.taskDescription}`))
  .then(window.responsiveVoice.speak(`${this.state.taskA}`))
  .then(window.responsiveVoice.speak(`${this.state.taskB}`))
             
}

async openSaveToPortfolioModal(){
 console.log('running')
 console.log(this.state.html)
  $('.editorCase').fadeOut('fast',()=>{
       $('.portfolioCase').fadeIn('slow')
   })

}

updateProjectName = (event) => { 
  this.setState({
        projectName: event.target.value
   })

   let projectTitle = document.getElementById('projectTitle')

   projectTitle.innerHTML = $('#projectName').val()
}

    
async Imageupload(e) {

  await  $.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
  // Initiate upload

var myWidget = window.cloudinary.createUploadWidget({
cloudName: 'www-code4teen-tech', 
uploadPreset: 'csizcotf'}, (error, result) => { 
if (!error && result && result.event === "success") { 
  console.log('Done! Here is the image info: ', result.info.secure_url); 
    this.setState({
         cloudImage:result.info.secure_url
       })
    this.props.taskStatus(true)
}
}
)


document.getElementById("upload_widget").addEventListener("click", function(){
myWidget.open();
}, false);


}




async storeCodeToPortfolio(){ 

  if(this.state.projectName === "" || this.state.cloudImage === ""){
    return this.props.portfolioSave(false)
  }

  const data = {
    projectName: this.state.projectName,
    projectImage: this.state.cloudImage,
    code: this.state.html
  }

const skinUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/porfolio-api/vi/createNewClassProject', { 
  method:"POST",
  headers:{
    "content-type":"application/JSON"
  },
  body:JSON.stringify(data),
  mode:"cors",
  credentials:'include' 
})

.then(response => response.json())
       .then(response => { 
           console.log(response)

           this.props.portfolioSave(response)
       })      




}


 
 async liftHtmlState(){
  let thtml =  this.state.html
  await thtml.trim()
  console.log(thtml)
  this.props.htmlChange(this.state.html)
 }

 //running the editor code 
 runCode = () => {

  const {html} = this.state;

  const iframe = this.refs.iframe;
  const document = iframe.contentDocument;
  const documentContents = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <title>Document</title>
      <style>
     
      </style>
    </head>
    <body>
      ${html}
    </body>
    </html>
  `;

  document.open();
  document.write(documentContents);
  document.close();


};

//rendering the component as JSX 

      render() {

           

        const { html } = this.state;
        const codeMirrorOptions = {
          mode: 'htmlmixed', 
          tabMode: 'indent',
          theme: 'lucario',
          value: this.state.codeSnippet,
          lineNumbers: true,
          lineWrapping: true,
          smartIndent: true,
          electricChars: true,
          autofocus: true,
          lineSeparator:"    "
          
        };
       
       const  taskArray1=[ this.state.taskA, this.state.taskB, this.state.taskC, this.state.taskD,this.state.taskE ]
       const  taskArray2=[ this.state.taskE, this.state.taskF, this.state.taskG]
       const  taskList1 = taskArray1.map(task => <p className="blue-text body-font mobile-text-size"> {task} </p> )
       const  taskList2 = taskArray2.map(task => <p className="blue-text body-font mobile-text-size"> {task} </p> )

         
//jsx creation 

        return (
          <div>
          <div className="editorCase" >
          <div className="standard-padding-tb-2 black-background top-right-curve bottom-right-curve" id="editor-properties">
          <div className="row standard-padding-tb-2" id="editor-instructions"> 
             <div className="col-12 col-md-3 text-center"> 
             <figure> 
              <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={atclogo} width="100"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background"> Editor task </p> 
               <p className="accent-font tiny-font white-text"> Complete the tasks by following the instructions </p>
              </figcaption> 
             </figure>
             </div> 
             <div className="col-12 col-md-9 text-center"> 
              <div className="row standard-padding-tb-2 text-center"> 
              <div className="col-12"> 
               <h5 className="body-font blue-text mobile-text-size"> {this.state.taskDescription} </h5> 
              </div>
              <div className="col-12 task-steps-box">
                {taskList1}
              </div> 
              <div className="col-12">
            <button id="" className="btn btn-outline-success" onClick={this.openSaveToPortfolioModal}> Save </button>
            </div>
          </div>  

              
               

             </div> 
     
          </div> 
         
          <div className="App row">
         
            <section className="playground col-12 col-md-6">
              <div className="code-editor html-code">
                <div className="editor-header">HTML</div>
                <CodeMirror
                  value={html}
                  options={{
                    mode: 'htmlmixed',
                    ...codeMirrorOptions,
                  }}
                  onBeforeChange={(editor, data, html) => {

                    this.setState({ html }, () => this.liftHtmlState());
                  }}

                  onChange={this.runCode}
                  
                />
              </div>
            </section>
            <section className="result col-12 col-md-6 white-background padding-off">
              <iframe title="result" className="iframe" ref="iframe" />
            </section>
          </div>

          </div>  
          </div>

  <div className="portfolioCase">

 
          <div className="row standard-padding-tb-peditor black-background">
  
          <div className="col-8">

             <h2 className="accent-font white-text">  Save your new project </h2>
             <h6 className="body-font white-text">  To save a new project, fill in the following details and press save to continue </h6>


          <div className="col-12 text-center" id="createProject"> 

                    <div className="form-group text-center standard-padding-tb">
                      <label className="body-font white-text">Project Name</label>
                        <input type="text" value={this.state.projectName} onChange={this.updateProjectName} className="form-control input-field-width mx-auto standard-round-box-curve" id="projectName" aria-describedby="emailHelp" placeholder="Name of project"/>
                      </div>


                    <div className="col-9 form-group text-center standard-padding-tb mx-auto">
                      <label className="body-font white-text">Project Image</label>
                       
                      <button id="upload_widget" className="btn btn-outline-success cloudinary-button" > Upload a picture and recieve a URl below </button>
         
           
    
                      </div>
        

    
          <button className="btn btn-success standard-round-box-curve green-background white-text header-font tabs-btn-hover" onClick={this.storeCodeToPortfolio}>   Save Project </button>



         </div> 


          </div>  

  <div className="col-4"> 

      <h3 className="accent-font white-text"> Project Preview </h3>
      <h6 className="body-font white-text"> Confirm your project details before saving </h6> 

    <div className="row">

      <div className="col-12"> 
      

     <h6 className="white-text"> 
       PROJECT TITLE
     </h6>
        <h3 className="header-font white-text" id="projectTitle"> 
           
        </h3>
        <h6 className="white-text"> 
       PROJECT IMAGE
     </h6>

        <img width="90%" src={this.state.cloudImage} />

      </div>
    </div>

  </div> 

  </div>
  </div>


  </div>
        );
      }
    }

    export default Mirror
import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import TheWeb from '../learningmodules/theweb';
import loader from '../../images/loader.png';
import CanvasIntro from '../canvas-opts/canvas-intro';
import CanvasSuccess from '../canvas-opts/success';
import CanvasA from '../canvas-opts/canvasA';
import CanvasB from '../canvas-opts/canvasB';
import CanvasC from '../canvas-opts/canvasC';
import CanvasD from '../canvas-opts/canvasD';
import CanvasE from '../canvas-opts/canvasE';
import CanvasF from '../canvas-opts/canvasF';
import CanvasG from '../canvas-opts/canvasG';
import CanvasH from '../canvas-opts/canvasH';
import CanvasI from '../canvas-opts/canvasI';
import CanvasJ from '../canvas-opts/canvasj';
import CanvasK from '../canvas-opts/canvask';
import CanvasL from '../canvas-opts/canvasl';
import Editor  from '../editors/playground';
import Editor1 from '../editors/pEditor.js';
import ClassPractice from '../editors/classPractice.js';
import Equiz from '../quiz-dir/editorQuiz.js';
import EquizB from '../quiz-dir/editorquizB.js';
import Upload  from '../upload';
import Uploader from '../editors/upload-pg.js';
import QuizA from '../quiz-dir/who-am-i';
import Quizi from '../quiz-dir/Interface-Quiz.js';
import Lab from '../lab';
import classPractice from '../editors/classPractice';



 class web extends Component {

        constructor(props){
        	super(props);

        	this.state={
        ref:"", 
        heading:"",
        resourceicon:"", 
        explanationA:"",
        explanationB:"",
        explanationC:"",
        explanationD:"",
        codeSnippet:"",
        visualA:"",
        visualB:"",
        visualC:"",
        visualD:"",
        downloadable:"",
        tag:"",
        codeData:"",
        taskComplete:""
        	}

        	this.fetchResource = this.fetchResource.bind(this);

        }


    async componentWillMount(){ 
      console.log('will mount')
    } 


    async componentDidMount(){ 
       const { match:{ url, params }} = this.props; 

        console.log('fetch1')
        await this.fetchResource()

        this.setState({
            taskComplete: false
        });
    }

   // async shouldComponentUpdate(prevProps, prevState){
    //	const {match:{ url, params }} = this.props;
     //   console.log(this.state.ref)
      //  console.log(prevState.ref)
    //}

    async componentWillReceiveProps(nextProps){
       const {match:{ url, params }} = this.props;  
                  
    }

   async componentDidUpdate(prevProps){
    const {match:{ url, params }} = this.props;
   	   // console.log(params.resourceRef)
   	   // console.log(prevProps.match.params.resourceRef)

  if(params.resourceRef != prevProps.match.params.resourceRef && params.resourceRef ){
        await this.fetchResource();
         //console.log('receive props')
   
       }

     console.log(this.state.taskComplete)
   }




 
async fetchResource() { 
	//console.log('fetching')

    const { match: { params } } = this.props;

    //console.log(params.resourceRef)

    this.props.loaderOpen()

    
    const requestedResource = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${params.resourceRef}`,{
      method:"POST",
      headers:{
        'Content-Type': "application-JSON"
      }, 
      mode:"cors", 
      credentials:"include"
    })
       .then(response => response.json())
       .then(response => { 

         this.setState({ 
                  ref:response.ref, 
                  heading:response.heading,
                  resourceicon: response.resourceIcon, 
                  explanationA: response.explanationA,
                  explanationB: response.explanationB,
                  explanationC: response.explanationC,
                  explanationD: response.explanationD,
                  visualA: response.visualA,
                  visualB: response.visualB,
                  visualC: response.visualC,
                  visualD: response.visualD,
                  downloadable: response.downloadable,
                  tag: response.tag
               },  this.props.loaderClose()) 

             

       })


 }


    render(){

      const { match: { params } } = this.props;

      console.log('i ran')
    //console.log(this.state.ref)
    //console.log(params.resourceRef)

    //check state has been updated with new values, if not return loader
      	if(this.state.ref != params.resourceRef){
      		return <div> </div> 
      	}

    //return canvas element if state has been updated with new values

      return(


      	<div className="">
         
      	{ (() => {
      		//console.log('i ran')
       switch(params.resourceRef){
        
        case 'class-plan-web':
           return <CanvasIntro height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
        
        case 'class-plan-publishing':
            return <CanvasIntro height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
        case 'class-plan-web':
               return <CanvasIntro height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
        case 'class-plan-tags':
               return <CanvasIntro height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
        case 'class-plan-storage':
                     return <CanvasIntro height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
        case 'class-success-tags':
                        return <CanvasSuccess height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
        case 'class-success-web':
                           return <CanvasSuccess height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
        case 'class-success-publishing':
                              return <CanvasSuccess height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
        case 'class-success-storage':
                                 return <CanvasSuccess height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
                                    
        case 'theweb':
           return <CanvasA height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'webpage':
           return <CanvasB height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} explanationD={this.state.explanationD} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} visualD={this.state.visualD} />
        case 'website1':
           return <CanvasC height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'html1':
           return <CanvasC height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'website':
           return <CanvasC height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'html':
           return <CanvasB height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} explanationD={this.state.explanationD} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} visualD={this.state.visualD} />;
        case 'editor-task-a':
           return <Editor   resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate}  htmlChange={this.props.onHtmlChange} byteState={this.props.byteStateValue} taskStatus={this.props.taskStatusUpdate} />;
        case 'editor-task-b':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} byteState={this.props.byteStateValue} taskStatus={this.props.taskStatusUpdate} />;
        case 'editor-task-c':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} byteState={this.props.byteStateValue} taskStatus={this.props.taskStatusUpdate} />;
        case 'editor-task-d':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} byteState={this.props.byteStateValue} taskStatus={this.props.taskStatusUpdate} />;
        case 'editor-task-e':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} byteState={this.props.byteStateValue} taskStatus={this.props.taskStatusUpdate} />;
        case 'who-am-i-a':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-b':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-c':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-d':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-e':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;    
        case 'tags':
           return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
        case 'tag-basics-a':
           return <CanvasB height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} explanationD={this.state.explanationD} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} visualD={this.state.visualD} />
        case 'tag-basics-b':
           return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'structure-intro':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} />;       
        case 'doctype':
            return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'page-structure':
           return <CanvasE height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} explanationB={this.state.explanationB} explanationC={this.state.explanationC} explanationD={this.state.explanationD} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} visualD={this.state.visualD} />;
        case 'editor-task-f':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'editor-task-g':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'editor-task-h':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'editor-task-i':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'editor-task-j':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'who-am-i-f':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-g':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-h':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-i':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-j':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'text-publishing':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'text-publishing-a':
           return <CanvasB height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} explanationD={this.state.explanationD} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} visualD={this.state.visualD} />
        case 'headings':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'paragraphs':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'lists':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'editor-task-k':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'editor-task-l':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'editor-task-m':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'editor-task-n':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'editor-task-o':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
        case 'who-am-i-k':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-l':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-m':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'who-am-i-n':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
         case 'who-am-i-o':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
        case 'storage-basics-a':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
        case 'local-storage':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} explanationD={this.state.explanationD} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} visualD={this.state.visualD} />
       case 'cloud-storage':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} explanationD={this.state.explanationD} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} visualD={this.state.visualD} />
       case 'cloud-address':
           return <CanvasF height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
       case 'cloud-upload':
          return <Upload  resourceRef={params.resourceRef} heading={this.state.heading} explanationA={this.state.explanationA} visualA={this.state.visualA} taskStatus={this.props.taskStatusUpdate} />;
       case 'editor-task-p':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-q':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-r':
           return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-s':
           return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-t':
           return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'who-am-i-p':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
       case 'who-am-i-q':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
       case 'who-am-i-r':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
       case 'who-am-i-s':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
       case 'who-am-i-t':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
       case 'who-am-i-u':
           return <QuizA   resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
       case 'tag-positions-1':
            return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'tag-positions-2':
            return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'tag-positions-3':
            return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'tag-positions-4':
            return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
      case 'tag-positions-5':
            return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;   
      case 'editor-task-aa':
            return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ab':
            return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ac':
            return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ad':
            return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ae':
             return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'code-analysis-a':
             return <Equiz  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
       case 'code-analysis-b':
             return <Equiz  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse}  />;
       case 'code-analysis-c':
             return <Equiz  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse}  />;
       case 'code-analysis-d':
             return <Equiz resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse}  />;                                                                                                                                                                            
       case 'container-tag-1':
               return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'container-tag-2':
               return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'container-tag-3':
               return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'container-tag-4':
               return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'container-tag-5':
               return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'container-tag-6':
               return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'container-tag-7':
               return <CanvasJ height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow} />;
       case 'editor-task-af':
               return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ag':
              return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ag-1':
               return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ah':
               return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ai':
               return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-aj':
             return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'code-analysis-e':
             return <Equiz  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse} />;
       case 'code-analysis-f':
             return <Equiz  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse}  />;
       case 'code-analysis-g':
             return <Equiz  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse}  />;
       case 'code-analysis-h':
             return <Equiz resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateQuizResponse}  />;                                                                                                                                                                            
       case 'attributes-1':
             return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'attributes-2':
             return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'attributes-3':
             return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'attributes-4':
             return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'attributes-5':
             return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'attributes-6':
             return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'attributes-7': 
             return <CanvasJ height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable} activateArrow={this.props.activateForwardArrow} taskStatus={this.props.taskStatusUpdate}/>;
       case 'editor-task-ak':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-al':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
         case 'editor-task-am':
              return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
      case 'editor-task-an':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ao':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ap':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-aq':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-ar':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-as':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-at':
              return <Editor1 resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} portfolioSave={this.props.confirmPortfolioSave} />;
       case 'code-analysis-i':
              return <EquizB  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
       case 'code-analysis-j':
              return <EquizB  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
       case 'code-analysis-k':
              return <EquizB  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
       case 'code-analysis-l':
              return <EquizB  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
       case 'styling-defined':
              return <CanvasD height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} />;
       case 'styling-text-1':
              return <ClassPractice resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'styling-text-2':
              return <ClassPractice resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'styling-text-3':
              return <ClassPractice resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'styling-sections-1':
              return <ClassPractice resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'styling-divisions-1':
              return <ClassPractice resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'styling-project':
              return <CanvasJ height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow} taskStatus={this.props.taskStatusUpdate} />;
       case 'editor-task-styling-a':
              return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-b':
               return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-c':
               return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-d':
                return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-e':
                return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-f':
                return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-g':
                return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-h':
                return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-i':
                return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-j':
                return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-k':
                return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-l':
                return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-m':
                  return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-n':
                  return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-o':
                  return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-p':
                  return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
      case 'editor-task-styling-q':
                  return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-r':
                  return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-s':
                  return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-t':
                  return <Uploader  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-u':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-v':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-w':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-x':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-y':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />
       case 'editor-task-styling-v1':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-w1':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-x1':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-y1':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-v2':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-w2':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-x2':
                   return <Editor  resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-y2':
                   return <Editor resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} />;
       case 'editor-task-styling-z':
          return <Editor1 resourceRef={params.resourceRef} validatingResponse={this.props.validateResponse} eventValidatingResponse={this.props.eventValidateResponse} checkTaskCompletionUpdate={this.props.taskCompletionUpdate} htmlChange={this.props.onHtmlChange} taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} portfolioSave={this.props.confirmPortfolioSave} />; 
       case 'class-plan-9': 
          return <CanvasIntro height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} fullname={this.props.candidateFirstName} avatar={this.props.candidateAvatar} />;
       case 'interface-analysis-1': 
          return <CanvasK height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow}/>;
       case 'interface-analysis-2': 
          return <CanvasL height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
       case 'interface-analysis-3': 
          return <CanvasL height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} visualB={this.state.visualB} visualC={this.state.visualC} />;
       case 'interface-analysis-4': 
          return <Quizi  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
       case 'interface-analysis-5': 
          return <CanvasK height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow}/>;
       case 'interface-analysis-6': 
          return <interfaceQuiz resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
       case 'interface-analysis-7': 
          return <CanvasK height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow}/>;
       case 'interface-analysis-8': 
          return <interfaceQuiz  resourceRef={params.resourceRef} quizAnswer={this.props.quizAnswerUpdate}  taskStatus={this.props.taskStatusUpdate} byteState={this.props.byteStateValue} quizResponse={this.props.quizResponseUpdate} validateQuiz={this.props.validateAnalysisQuizResponse}  />;
       case 'interface-analysis-9': 
          return <CanvasK height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow}/>;
       case 'interface-analysis-10': 
          return <CanvasK height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow}/>;
       case 'interface-analysis-11': 
          return <CanvasK height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow}/>;
       case 'interface-analysis-12': 
          return <CanvasK height={this.props.dynamicHeight} width={this.props.dynamicWidth} heading={this.state.heading} explanationA={this.state.explanationA} taskStatus={this.props.taskStatusUpdate} explanationB={this.state.explanationB} explanationC={this.state.explanationC} visualA={this.state.visualA} downloadable={this.state.downloadable}  activateArrow={this.props.activateForwardArrow}/>;
                                                                                                                                   
           
     }
      	})()}


      	

      
       </div>

    	)	

    }
  


}

export default web